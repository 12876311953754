<template>
  <div>
    <!-- :effect="$route.name === tag.name ? 'drak' : 'plain'" 判断是否高亮 -->
    <!-- <el-tag :key="tag.name" v-for="(tag, index) in tags" :closable="tag.name !== 'home'" size="small"
      :effect="$route.name === tag.name ? 'dark' : 'plain'" @close="handleClose(tag, index)"
      @click="changeMenu(tag, index)" @contextmenu.prevent.native="openMenu(tag, index, $event)" style="cursor: pointer">
      {{ tag.label }}
    </el-tag> -->
    <el-tabs v-model="editableTabsValue" type="border-card">
      <el-tab-pane :key="tab.name" v-for="(tab, index) in tabs" :name="tab.name">
        <div class="tag-wrap" slot="label">
          <el-tag class="tag-full" :key="tab.name" :closable="tab.name !== 'Index'" size="small"
            :effect="$route.name === tab.name ? 'dark' : 'plain'" @close="handleClose(tab, index)"
            @click="changeMenu(tab, index)" @contextmenu.prevent.native="openMenu(tab, index, $event)"
            style="cursor: pointer">
            {{ tab.label }}
          </el-tag>
        </div>
        <!-- <components :is="tab.content" :key="componentsRandom[tab.name]"></components> -->
      </el-tab-pane>
    </el-tabs>
    <ul v-show="visible" :style="{ left: left + 'px', top: top + 'px' }" class="contextmenu">
      <li @click="refreshSelectedTab()">刷新</li>
      <li @click="closeSelectedTab()">关闭</li>
      <li @click="closeOthersTabs()">关闭其他</li>
      <li @click="closeAllTabs()">关闭所有</li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapMutations, store } from "vuex";
export default {
  name: "Tabs",
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTab: {},
      selectedTabIndex: 0,
      editableTabsValue: 'Index',
    };
  },
  created() {
    let tabs = JSON.parse(localStorage.getItem('tabs'))
    if (!tabs) {
      tabs = [
		  	{
		  		path: "/Index",
		  		name: "Index",
		  		label: "首页",
		  		icon: "s-home",
		  		url: "Index/index",
		  	},
		  ]
    }
    this.initTabs(tabs)
    // if (this.tabs.length === 1) {
    //   this.$router.push({
    //     name: 'Index',
    //   });
    //   return
    // }
    this.editableTabsValue = this.$route.name
  },
  updated() {
    this.editableTabsValue = this.$route.name
  },
  methods: {
    ...mapMutations({
      close: "closeTab",
      closeAllTab: "closeAllTab",
      initTabs: "initTabs",
      closeOthersTab: "closeOthersTab",
    }),
    handleClose(tab, index) {
      const length = this.tabs.length - 1;
      // 判断选中的tab是否与当前路由一致
      this.close(tab);
      if (tab.name !== this.$route.name) {
        return;
      }
      if (index === length) {
        this.$router.replace({
          name: this.tabs[index - 1].name,
        });
      } else {
        this.$router.replace({
          name: this.tabs[index].name,
        });
      }
    },
    changeMenu(item) {
      // 点击实现路由跳转
      this.$router.replace({
        path: item.path,
      });
    },
    refreshSelectedTab() {
      // this.componentsRandom[this.selectedTab.name] = new Date().getTime()
      this.$router.replace({
        path: '/redirect' + this.selectedTab.path
      })
    },
    closeSelectedTab() {
      if (this.selectedTabIndex === 0) {
        return;
      }
      this.handleClose(this.selectedTab, this.selectedTabIndex)
    },
    closeOthersTabs() {
      this.closeOthersTab(this.selectedTabIndex)
      this.$router.push({
        name: this.tabs[this.tabs.length - 1].name,
      });
    },
    closeAllTabs() {
      this.closeAllTab()
      this.$router.push({
        name: "Index",
      });
    },
    openMenu(tab, index, e) {
      const menuMinWidth = 105
      const left = e.clientX + 15 // 15: margin right
      const clientWidth = document.body.clientWidth

      if (clientWidth - e.clientX < menuMinWidth) {
        this.left = e.clientX - menuMinWidth
      } else {
        this.left = left
      }

      this.top = e.clientY
      this.visible = true
      this.selectedTab = tab
      this.selectedTabIndex = index
    },
    closeMenu() {
      this.visible = false
    },
  },
  computed: {
    ...mapState({
      tabs: (store) => store.tab.tabsList,
    }),
  },
  watch: {
    tabs (value) {
      let arr = value
      if (arr instanceof Array) {

      } else {
        arr = [{
		  		path: "/Index",
		  		name: "Index",
		  		label: "首页",
		  		icon: "s-home",
		  		url: "Index/index",
		  	}]
      }
      localStorage.setItem('tabs', JSON.stringify(arr))
      localStorage.setItem('tabsList', JSON.stringify(arr.map(item => {
        return item.name
      })))
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    },
    $route() {
      this.editableTabsValue = this.$route.name
    }
  },
};
</script>

<style scoped>
.el-tag+.el-tag {
  margin-left: 10px;
}
.el-tabs--border-card{
  height: 40px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.el-tabs__item {
  padding: 0 !important;
}

.el-tabs--card>.el-tabs__header .el-tabs__item .el-icon-close {
  width: 14px;
  height: 14px;
  color: #FFF;
  background-color: #66b1ff;
  font-size: 12px;
}

.el-tag--dark .el-tag__close {
  color: #409eff;
}

.el-tabs__content {
  display: none;
}

.el-tabs--border-card {
  border-bottom: none;
  box-shadow: none;
}
</style>
<style lang="less">
.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);

  li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;

    &:hover {
      background: #eee;
    }
  }
}

.tag-wrap {
  border: none;
  width: 100%;
  height: 100%;
}

.tag-full {
  display: block;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  border: none;
  background-color: transparent;
  color: #409eff;
}
</style>